import React from 'react';
import Header from './Header';

import '../styles/global.scss';

export default function Layout({ props, location, children }) {
	return (
		<>
			<div>
				{children}
			</div>
		</>
	);
}