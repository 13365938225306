import React from 'react';
import { kebabCase } from '../../helpers/kebabCase';
import { graphql, StaticQuery, Link } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';
import '../../styles/components/practice-navigation.scss';

let section;

class PracticeNavigation extends React.Component {

	showPanel = ( el, delay ) => {

		let section1 = document.querySelector( '[data-section="0"]' );
    	let section2 = document.querySelector( '[data-section="1"]' );
    	let link1 = document.querySelector( '[data-link-count="0"]' );
    	let link2 = document.querySelector( '[data-link-count="1"]' );
    	let currentLink = document.querySelector( '[data-link="' + el + '"]' );
		let currentSection = document.getElementById( el );
		
		setTimeout(() => {
			section1.classList.remove( 'active-panel' );
			section2.classList.remove( 'active-panel' );
			link1.classList.remove( 'active-nav-state' );
			link2.classList.remove( 'active-nav-state' );
			currentLink.classList.add( 'active-nav-state' );
			currentSection.classList.add( 'active-panel' );
		}, delay);

	}

	render() {
		return (
			<>
				<StaticQuery 	
					query={graphql`
						query practiceNav {
						  wpPage(id: {eq: "cG9zdDozMQ=="}) {
						    practiceSections {
						      practice {
						        sectionTitle
				                shortSectionTitle
						      }
						    }
						  }
						}`
					}
					render={ (practiceNav)  => (
						<>
							
							{ this.props.parentPage === 'practice' && (
								<ul className={ 'practice-navigation text:tracked flex' }>
									<li><Link className={ 'practice-navigation text:sm uppercase mobile active-nav-state' } to={ '/practice' }>Studio</Link></li>
									{ practiceNav.wpPage.practiceSections.practice.map((section, i) => (
										<li className={ 'desktop' }><Link 
											to={ '/practice' } 
											data-panel={ kebabCase( section.sectionTitle ) } 
											onClick={( e ) => {
												let active = document.querySelector( '.active-nav-state' );
												active.classList.remove( 'active-nav-state' );
												section = e.target.dataset.panel;
												this.props.navVisibility();
												this.showPanel( section, 0 );
											}} 
											key={i}>{ section.sectionTitle }</Link></li>
									))}
								</ul>
							)}

							{ this.props.parentPage === 'home' && (
								<ul className={ 'practice-navigation text:tracked flex' }>
									<li className={ 'mobile' }><Link className={ 'practice-navigation text:sm uppercase mobile' } to={ '/practice' }>Studio</Link></li>
									{ practiceNav.wpPage.practiceSections.practice.map((section, i) => (
										<li className={ 'desktop' }>
											<TransitionLink 
												to={ '/practice' }
												data-link-count={ i }
												data-panel={ kebabCase( section.sectionTitle ) } 
												onClick={(e) => {
													section = e.target.dataset.panel;
												}}
												entry={{
												    delay: 0.1,
												    trigger: ({ exit, node }) => {
												    	this.showPanel( section, 1 );
												    }
												}}
												>
										{ section.sectionTitle }</TransitionLink>
										</li>
									))}
								</ul>
							)}
						</>
					)}
				/>			
			</>
		)
	}
}

export default PracticeNavigation;