import React, { Component } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import AnchorLink from 'react-anchor-link-smooth-scroll';

let cover, transitionWrapper, list, listItems;

class MobileNavigation extends React.Component {

	constructor( props ) {
		super( props );
	}

	componentDidMount() {
		
		cover = document.createElement( 'div' );
		cover.classList.add( 'cover' );
		transitionWrapper = document.querySelector( '.tl-wrapper' );

	}

	scrollToItem = ( event, item ) => {
		let top;

		setTimeout(() => {

			event.preventDefault();
			if( !document.querySelector( 'aside' ) ) return;

			top = document.querySelector( 'aside' ).offsetTop - 2;

			let id = item.parentNode.dataset.active;
			let section = document.querySelector( '[data-section="'+id+'"]' ).offsetTop; 
			let scrollValue = section - top;

			window.scrollTo(0, scrollValue);

		}, 250);

	}

	kebabCase = (str) => {
	    return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
	    .join('-')
	    .toLowerCase();
	}

	render() {
		
		let content = this.props.practiceLinks[0];

		return (
			<nav className={ 'site-nav' }>
				<ul className={ 'text:sm' }>
					
					<li>
						<a 
							href="/work-index"
							onClick={(e) => {
								e.preventDefault();
								{ this.props.indexVisibility ? this.props.handleClose() : this.props.handleIndex() }
							}}
						>
						Project index</a>
					</li>

					{ content.sections.sections.map((section, i) => {

						if( section.sectionTitle === 'Practice' ) {
							return (
								<li key={i}
									data-active={ i }
									className={ 'active' }
								>
									<TransitionLink
										onClick={(e) => {
											this.scrollToItem(e, e.target);
										}}
										to={ '/practice' }
										exit={{
									  	trigger: ({ exit, node }) => {
									    	transitionWrapper.appendChild( cover );

									    	setTimeout(() => {
										    	document.body.classList.add( 'show-cover' );
									    	}, 250);

									  	},
									    length: 1
									  }}
									  entry={{
									    delay: 1,
									    trigger: ({ exit, node }) => {

									    	setTimeout(() => {
									    		document.body.classList.add( 'has-nav' );
									    	}, 25);

									    	setTimeout(() => {
										    	document.body.classList.remove( 'show-cover' );
									    	}, 500);

									    	setTimeout(() => {
									    		cover.remove();
									    	}, 2000);
									    }
									  }}
									>
										{ section.sectionTitle }
									</TransitionLink>
								</li>
							);
						} else {
							return (
								<li key={i}
									data-active={ i }
									>
									<TransitionLink
										onClick={(e) => {
											this.scrollToItem(e, e.target);
										}}
										to={ "/practice#" + this.kebabCase( section.sectionTitle ) }
										exit={{
									  	trigger: ({ exit, node }) => {
									    	transitionWrapper.appendChild( cover );

									    	setTimeout(() => {
										    	document.body.classList.add( 'show-cover' );
									    	}, 1);

									  	},
									    length: 1
									  }}
									  entry={{
									    delay: 1,
									    trigger: ({ exit, node }) => {

									    	setTimeout(() => {
									    		document.body.classList.add( 'has-nav' );
									    	}, 25);

									    	setTimeout(() => {
										    	document.body.classList.remove( 'show-cover' );
									    	}, 500);

									    	setTimeout(() => {
									    		cover.remove();
									    	}, 750);
									    }
									  }}
									>
										{ section.sectionTitle }
									</TransitionLink>
								</li>
							);
						}

					}) }

				</ul>
			</nav>
		);

	}

}

export default MobileNavigation;