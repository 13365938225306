import React, { Component } from 'react';
import Navigation from './Navigation';
import { graphql, StaticQuery, Link } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link'
import MobileNavigation from './utils/MobileNavigation';

import '../styles/components/site-header.scss';
import '../styles/components/wordmark.scss';
import '../styles/transitions/practice.scss';

let cover, transitionWrapper;

class Header extends React.Component {
	constructor( props ) {
		super( props );
	}
	
	render() {
		return (
			<StaticQuery 
				query={graphql`
					query Header {
					  wp {
					    generalSettings {
					      title
					    }
					  }
					  allWpPage(filter: {id: {eq: "cG9zdDozMQ=="}}) {
					    nodes {
					      title
					      id

					      uri
					      slug
					    }
					  }
					}`
				}
				render={data => (
					<header className="site-header">
						{ this.props.parentPage === 'home' && (
							<p className={ 'wordmark text:wordmark flex' }>
								<Link to={ '/' } onClick={(e) => {
									if( this.props.indexVisibility === false ) {
										e.preventDefault();
										this.props.indexOpen();
									} else {
										e.preventDefault();
										this.props.navVisibility();
									}
								}}>
									<span className={ 'flex' }>
										<span>{ data.wp.generalSettings.title }</span>
										<span className={ 'asterism-label' }>{ this.props.indexVisibility ?  '\u00D7' : "\u2042" }</span>
									</span>
								</Link>
							</p>
						)}

						{ this.props.parentPage === 'practice' && (
							<p className={ 'wordmark text:wordmark flex' }>
								<Link to={ '/' }>
									<span>{ data.wp.generalSettings.title }</span>
								</Link>
								<Link to={ '/' } onClick={(e) => {
									if( this.props.indexVisibility === false ) {
										e.preventDefault();
										this.props.indexOpen();
									} else {
										e.preventDefault();
										this.props.navVisibility();
									}
								}}>
									<span className={ 'asterism-label' }>{ this.props.indexVisibility ?  '\u00D7' : "\u2042" }</span>
								</Link>
							</p>
						)}

						{ this.props.parentPage === 'terms' && (
							<p className={ 'wordmark text:wordmark flex' }>
								<Link to={ '/' }>
									<span className={ 'flex' }>
										<span>{ data.wp.generalSettings.title }</span>
										<span className={ 'asterism-label' }>{ this.props.indexVisibility ?  '\u00D7' : "\u2042" }</span>
									</span>
								</Link>
							</p>
						)}
						<Navigation 
							findIndex={ this.props.findIndex }
							setTitle={ this.props.setTitle }
							navVisibility={ this.props.navVisibility } 
							parentPage={ this.props.parentPage }
							windowWidth={ this.props.windowWidth }
						/>
					</header>
				)}
			/>
		);

	}

}

export default Header;