import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Project from './navigation/Project';
import PracticeNavigation from './navigation/PracticeNavigation';
import '../styles/components/site-navigation.scss';
import '../styles/components/close.scss';
import '../styles/components/cursor.scss';

class Navigation extends React.Component {

	constructor( props ) {
		super( props );
		this.state = {
			cursorVisibility: false
		}
		this.setCursorVisibility = this.setCursorVisibility.bind( this )
	}

	componentDidMount() {
		this.moveCursor();
		console.log(this.props);
	}

	setCursorVisibility( visibility ) {
		this.setState({
			cursorVisibility: this.state.cursorVisibility = visibility
		})
	}

	moveCursor() {

		const body = document.body;
		const crosshair = document.querySelector( '.cursor' );

		body.addEventListener( "mousemove", (n, event) => {
			crosshair.style.left = n.clientX + "px";
			crosshair.style.top = n.clientY + "px";
		});

	}

	render() {

		return (
			<StaticQuery 
				query={graphql`
					query Navigation {
					  wp {
					    generalSettings {
					      title
					    }
					  }
					  wpPage(id: {eq: "cG9zdDozMQ=="}) {
					    id
					    title
					    practiceSections {
					    	practice {
						    	sectionTitle
					    	}
					    }
					  }
					  allWpProject(sort: {fields: menuOrder}) {
					    nodes {
					      title
				      	  comingSoon {
				      		comingSoon
				      	  }
					    }
					  }
					}`
				}
				render={nav => (
					<nav className="site-navigation flex">
						<div>
							<a className={ 'close' } onClick={(e) => {
								e.preventDefault();
								this.props.navVisibility();
							}}></a>

							<PracticeNavigation 
								navVisibility={ this.props.navVisibility } 
								parentPage={ this.props.parentPage } 
							/>

						</div>

						<div>
							<p className={ 'text:sm uppercase mobile' }>Projects</p>
							<ul>
								{ nav.allWpProject.nodes.map((group, i) => ( 
									<Project 
										key={ i }
										index={ i }
										content={ group }
										navVisibility={ this.props.navVisibility }
										findIndex={ this.props.findIndex }
										setTitle={ this.props.setTitle }
										parentPage={ this.props.parentPage }
										cursorVisibility={ this.setCursorVisibility }
										windowWidth={ this.props.windowWidth }
									/>
								))}
							</ul>
						</div>

						<p className={ this.state.cursorVisibility  === true ? 'cursor show' : 'cursor' }>
							<span className={ 'text:section' }>Coming soon</span>
						</p>
					</nav>
				)}
			/>
		);

	}

}

export default Navigation;