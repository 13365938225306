// scrollTo.js
import { animateScroll } from "./animateScroll";

export const scrollTo = (targetPosition, startingPosition, duration) => {
  const initialPosition = document.querySelector( '.track' ).scrollLeft;

    animateScroll({
      targetPosition: targetPosition,
      initialPosition: initialPosition,
      duration
    });
};
