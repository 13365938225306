import React, { useState } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { Link } from 'gatsby';
import { scrollTo } from '../../helpers/scrollTo';
import '../../styles/components/project.scss';
import '../../styles/content/coming-soon.scss';

export default function Project( props ) {
	const [ isComingSoon, setIsComingSoon ] = useState(false);

	let current, selected, scrollTarget, title, event, track, fixedTitle, titleText;
	let cursor;

	return (
		<>
			<li className={ 'text:nav uppercase project' }>

				{ props.parentPage === 'practice' && !props.content.comingSoon.comingSoon && (
					<TransitionLink 
						to={ '/' }
						onClick={(e) => {
							e.preventDefault();
					    	if( !props.content.comingSoon.comingSoon ) {
								selected = e.target.dataset.projectIndex;
								title = e.target.dataset.projectTitle;
							} 
						}}
						exit={{
						  	trigger: ({ exit, node }) => {
						  	},
						    length: 1
						  }}
						  entry={{
						    delay: 1,
						    trigger: ({ exit, node }) => {
						    	if( !props.content.comingSoon.comingSoon ) {
									setTimeout(() => {
										scrollTarget = document.querySelector( '[data-index="' + selected + '"]' );
										let firstProject = document.querySelector( '[data-project-index="0"]' );
										let scrollThreshold = scrollTarget.offsetLeft - ( document.querySelector( '.fixed-title li:nth-of-type(2) a' ).offsetLeft+24 );
										current = document.querySelector( '[data-project-index="' + selected + '"]' );
										firstProject.classList.remove( 'active-project' );
										current.classList.add( 'active-project' );
										fixedTitle = document.querySelector( '.fixed-title' );
										scrollTo( scrollThreshold, scrollTarget, 2500 );
										titleText = fixedTitle.querySelector( 'p' );
										titleText.innerHTML = '';
										titleText.innerHTML = title;
									}, 450);
						    	}
						    }
						}}
						onMouseOver={(e) => {
							e.preventDefault();
							if( props.content.comingSoon.comingSoon ) {
								cursor = document.querySelector( '.cursor' );
								cursor.style.opacity = '1';
							}
						}}
						onMouseOut={(e) => {
							e.preventDefault();
							if( props.content.comingSoon.comingSoon ) {
								cursor = document.querySelector( '.cursor' );
								cursor.style.opacity = '0';
							}
						}}
						data-status={ props.content.comingSoon.comingSoon ? 'coming-soon' : null  }
						data-project-index={ props.index }
						data-project-title={ props.content.title }>
						
						{ isComingSoon === true && (
							<>Coming Soon</>
						)}

						{ isComingSoon === false && (
							props.content.title
						)}
					</TransitionLink>
				) }

				{ props.parentPage === 'practice' && props.content.comingSoon.comingSoon && (
					<Link 
						to={ '/' }
						onClick={(e) => {
							e.preventDefault();
							setIsComingSoon(!isComingSoon);
						}}
						onMouseOver={(e) => {
							e.preventDefault();
							if( props.content.comingSoon.comingSoon ) {
								cursor = document.querySelector( '.cursor' );
								cursor.style.opacity = '1';
							}
						}}
						onMouseOut={(e) => {
							e.preventDefault();
							if( props.content.comingSoon.comingSoon ) {
								cursor = document.querySelector( '.cursor' );
								cursor.style.opacity = '0';
							}
						}}
						className={ props.index == 0 ? 'active-project' : '' }
						data-status={ props.content.comingSoon.comingSoon ? 'coming-soon' : null  }
						data-project-index={ props.index }
						data-project-title={ props.content.title }>
						
						{ isComingSoon === true && (
							<span className={ 'coming-soon' }>Coming Soon</span>
						)}

						{ isComingSoon === false && (
							props.content.title
						)}
					</Link>
				) }

				{ props.parentPage === 'home' && (
					<a 	href="#"
						onClick={(e) => {
							if( !props.content.comingSoon.comingSoon ) {
								event = e;
								current = document.querySelector( '.active-project' );
								selected = e.target.dataset.projectIndex;
								scrollTarget = document.querySelector( '[data-index="'+ selected +'"]' );
								let scrollThreshold = scrollTarget.offsetLeft - ( document.querySelector( '.fixed-title li:nth-of-type(2) a' ).offsetLeft+24 );

								title = document.querySelector( '[data-project-title="'+ e.target.dataset.projectTitle +'"]' );
								props.setTitle( title.dataset.projectTitle );
								current.classList.remove( 'active-project' );
								e.target.classList.add( 'active-project' );
								props.navVisibility();

								setTimeout(() => {
									scrollTo( scrollThreshold, scrollTarget, 2500 );
								}, 500);
							} else {
								setIsComingSoon(!isComingSoon);
							}
						}}
						onMouseOver={(e) => {
							e.preventDefault();
							if( props.content.comingSoon.comingSoon ) {
								cursor = document.querySelector( '.cursor' );
								cursor.style.opacity = '1';
							}
						}}
						onMouseOut={(e) => {
							e.preventDefault();
							if( props.content.comingSoon.comingSoon ) {
								cursor = document.querySelector( '.cursor' );
								cursor.style.opacity = '0';
							}
						}}
						className={ props.index == 0 ? 'active-project' : '' }
						data-status={ props.content.comingSoon.comingSoon ? 'coming-soon' : null  }
						data-project-index={ props.index }
						data-project-title={ props.content.title }>
						{ isComingSoon === true && (
							<span className={ 'coming-soon' }>Coming Soon</span>
						)}

						{ isComingSoon === false && (
							props.content.title
						)}					
					</a>
				) }

			</li>
		</>
	);

}